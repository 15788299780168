















































































































































































































































import Api from '@/includes/logic/Api'
import { InputSetups } from '@/mixins/input-setups'
import { Board, PostData } from '@/views/posts-planner/posts.types'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import isMobile from '@/assets/utils/isMobile'
import DatesTable from '@/views/posts-planner/components/DatesTable.vue'
import Quizes from '@/views/posts-planner/components/Quizes.vue'
import PostInfo from '@/views/posts-planner/components/Post/PostInfo.vue'
import AsyncPopup from '@/components/AsyncPopup.vue'
import { errorNotification, successNotification, warningNotification } from '@/includes/NotificationService'
import PageTitle from '@/components/PageTitle.vue'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FileType } from 'piramis-base-components/src/components/File/types'

import { cloneDeep } from 'lodash'
import moment from 'moment'
import Component from 'vue-class-component'
import { Mixins, Ref } from 'vue-property-decorator'
import FullCalendar, { CalendarOptions, EventClickArg } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import enLocale from '@fullcalendar/core/locales/en-gb'
import ruLocale from '@fullcalendar/core/locales/ru'

@Component({
  'components': {
    Quizes,
    FullCalendar,
    MobileLandscapeTriggerLayout,
    DatesTable,
    ConfigField,
    PostInfo,
    AsyncPopup,
    PageTitle
  },
  data() {
    return {
      FileType
    }
  }
})
export default class PostsPlanner extends Mixins(InputSetups, UseFields, DrawerWidthMixin) {

  isActive: boolean = false

  selectedNode: any = null

  board: string = ''

  selectOptionsChats: Array<any> = []

  newModel: any = {
    date: '',
    time: '',
    targets: '',
  }

  areButtonsSet = false

  eventsByDate: Array<PostData> = []

  currentBoard: Board = {} as Board

  @Ref('asyncPopup') asyncPopup!: AsyncPopup

  getRunTime(): string {
    return `${ this.newModel.date ? this.newModel.date : this.selectedNode.date } ${ this.newModel.time ? this.newModel.time : this.selectedNode.time }`
  }

  onClose():void {
    this.isActive = false
    this.selectedNode = null
    this.eventsByDate = []
  }

  get sidebarTitle(): string {
    if (this.selectedNode) {
      return this.$t('planner_page_post_preview').toString()
    }

    return this.$t('posts_planner_page_title').toString()
  }

  isPostPinned(post: PostData): boolean {
    let result = false
    if (post.post.message.type === 'Post') {
      result = post.post.message.variants.some(v => v.pin)
    }
    return result
  }

  removeEvent(data: any): void {
    this.$confirm({
      title: this.$t('planner_popup_title_remove_warn').toString(),
      content: this.$t('planner_popup_remove_warn').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk:() => {
        let event = this.calendarApi.getEventById(data.id)
        if (event) {
          Api.deletePost('tg', { board: this.board, key: event.id })
            .then(({ data }) => {
              event!.remove()
              successNotification()
              this.calendarApi.refetchEvents()
            })
            .catch(errorNotification)
        }
        this.isActive = false
      }
    })
  }

  editPost(data: any) {
    this.isActive = false

    this.$confirm({
      title: this.$t('planner_popup_title_edit_warn').toString(),
      content: this.$t('planner_popup_edit_redirect').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk:() => {
        this.routeChange = false
        this.$router.push({
          name: 'post',
          params: {
            id: this.$store.state.chatState.chat.chat_id,
            actionType: 'edit'
          },
          query: { postId: data.id }
        })
      }
    })
  }

  getDateString(date: string): string {
    return moment(date).format('HH:mm').toString()
  }

  makeTextPreview(text: string) {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(text, 'text/html')
    const textContent = htmlDoc!.firstChild!.lastChild!.textContent

    if (textContent && textContent.length > 50) {
      return `${ textContent.slice(0, 50) }...`
    }

    return textContent
  }

  get calendarOptions(): CalendarOptions {
    return {
      plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ],
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listMonth'
      },
      initialView: isMobile() ? 'listMonth' : 'dayGridMonth',
      editable: true,
      selectable: true,
      selectMirror: true,
      weekends: true,
      eventClick: this.handleEventClick,
      navLinks: true,
      dateClick: this.handleDateClick,
      locale: this.getFullCalendarLocale,
      locales: [ enLocale, ruLocale ],
      contentHeight: 1000,
      dayMaxEvents: true,
      views: {
        dayGridMonth: {
          dayMaxEvents: 2
        }
      },
      eventMaxStack: 3,
      lazyFetching: false,
      eventDidMount: (info) => {
        if (info.view.type === 'listMonth' && !this.areButtonsSet) {
          this.areButtonsSet = true
          this.setButtonsToDates()
        }
        this.$baseTemplate.loader.close()
      },
      datesSet: () => {
        this.areButtonsSet = false
      },
      moreLinkClick: (info) => {
        window.scrollTo(0, 0)
        return info.view.type = 'listMonth'
      },
      events: (fetchInfo, successCallback) => {
        this.$baseTemplate.loader.open()
        this.getDataFromApi(moment(fetchInfo.start).format('YYYY-MM-DD'), moment(fetchInfo.end).format('YYYY-MM-DD'))
          .then((returnEvents: any) => {
            let events = [ ...returnEvents ]
            if (this.calendarApi.view.type === 'timeGridWeek') {
              events = this.roundPostsTime(events)
            }
            this.$baseTemplate.loader.close()
            successCallback(events)
          })
      },
      eventClassNames: (arg) => {
        if (arg.event.extendedProps.run_time !== undefined) {
          if (arg.event.extendedProps.state === 'Complete') {
            return [ 'complete' ]
          } else if (arg.event.extendedProps.state === 'Active' && !arg.event.extendedProps.schedule.period) {
            return [ 'regular' ]
          } else if (arg.event.extendedProps.state === 'Error') {
            return [ 'error' ]
          } else if (arg.event.extendedProps.schedule.period && moment(arg.event.start).isAfter(moment())) {
            return [ 'repeat' ]
          } else if (arg.event.extendedProps.schedule.period && moment(arg.event.start).isBefore(moment())) {
            return [ 'complete' ]
          } else {
            return [ 'normal' ]
          }
        } else {
          return [ 'regular' ]
        }
      },
      eventDrop: (info) => {
        this.setDateTimeToNewModel(info.event.startStr)
        this.asyncPopup.open()
          .then(() => {
            Api.movePost('tg', {
              board_key: this.currentBoard.board.token_id + '.' + this.currentBoard.board.token_code,
              post_key: info.event.extendedProps.key,
              time: moment(`${ this.newModel.date } ${ this.newModel.time }`).format('YYYY-MM-DD HH:mm:ss')
            })
              .then(() => this.calendarApi.refetchEvents())
              .catch(errorNotification)
          })
          .catch(() => info.revert())
      },
      eventAllow: (dropLocation, draggedEvent) => {
        if (draggedEvent?.extendedProps.run_time.length > 1) {
          if (moment(dropLocation.startStr).isBefore(moment()) ||
            moment(draggedEvent?.startStr).format('YYYY-MM-DD HH:mm') !== moment(draggedEvent?.extendedProps.run_time[0]).format('YYYY-MM-DD HH:mm')
          ) {
            return false
          }
          return true
        } else {
          return !moment(dropLocation.startStr).isBefore(moment())
        }
      },
      moreLinkContent: (args) => {
        return '+' + args.num
      }
    }
  }

  roundPostsTime(posts: any[]): any[] {
    return posts.map((p: any) => {
      p.time = p.start
      if (moment(p.start).minute() >= 30) {
        p.time = p.start
        p.start = moment(p.start).minute(30).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      } else {
        p.start = moment(p.start).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      }
      return p
    })
  }

  setButtonsToDates() {
    const dateRows = document.getElementsByClassName('fc-list-day')
    const dateLink = document.getElementsByClassName('fc-list-day-cushion')

    Array.from(dateRows).forEach((dr: Element, index: number) => {
      const date = dr.getAttribute('data-date')
      if (!(Boolean(dateLink[index].children[2]) || !moment(date).isSameOrAfter(moment().startOf('day')))) {
        let button = document.createElement('span')
        button.innerText = this.$t('create_new_post_button').toString()
        let wrapper = document.createElement('div')
        wrapper.innerHTML = '<span class="material-icons">add_circle</span>'
        wrapper.className = 'add_wrapper'
        wrapper.onclick = () => {
          if (date) {
            this.createPost(date, '', { post_date: date })
          }
        }
        wrapper.append(button)
        dateLink[index].appendChild(wrapper)
      }
    })

  }

  setDateTimeToNewModel(date: string): void {
    const formatDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    this.newModel.date = formatDate.split(' ')[0]
    this.newModel.time = formatDate.split(' ')[1]
  }

  updatePost(newPost: any): void {
    const post: PostData = {
      'board': this.board,
      'schedule': {
        run_time: this.getRunTime(),
        timezone: newPost.schedule.timezone,
        period: newPost.schedule.period
      },
      'post': newPost.post,
      'targets': this.newModel.targets
    }
    Api.deletePost('tg', { 'board': this.board, 'key': newPost.key })
      .then(() => {
        Api.createPost('tg', post)
          .then(({ data }): any => {
            this.calendarApi.refetchEvents()
          })
          .catch(errorNotification)
      })
      .catch(errorNotification)
    this.isActive = false
  }

  get getFullCalendarLocale() {
    return localStorage.getItem('locale') === 'ru' ? ruLocale : enLocale
  }

  getSelectOptionsChats(): Promise<Array<any>> {
    return new Promise((resolve) => {
      resolve(this.selectOptionsChats)
    })
  }

  selectedDate = ''
  routeChange = true

  handleDateClick(args: DateClickArg) {
    if (this.routeChange) {
      this.isActive = true
      this.eventsByDate = []
      const eventsCopy = [ ...this.allTheEvents ]
      if (args.view.type === 'timeGridWeek') {
        this.selectedDate = args.dateStr.split('T')[0]
      } else {
        this.selectedDate = args.dateStr
      }
      eventsCopy.forEach((event: any) => {
        if (event.start.split(' ')[0] === this.selectedDate) {
          this.eventsByDate.push(event)
        }
      })
    }
  }

  createPost(date: string, time: string, queryParams: any) {
    this.isActive = false
    this.routeChange = false
    setTimeout(() => {
      if (date >= moment().format('YYYY-MM-DD')) {
        this.$router.push({
          name: 'post',
          params: {
            id: this.$store.state.chatState.chat.chat_id,
            actionType: 'new'
          },
          query: { ...queryParams }
        })
      } else {
        warningNotification(this.$t('planner_choose_other_date_warn').toString())
      }
    }, 500)
  }

  get calendarApi() {
    return (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi()
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (this.routeChange) {
      this.isActive = true
      if (this.calendarApi.view.type !== 'timeGridWeek') {
        this.setDateTimeToNewModel(clickInfo.event.startStr)
      } else {
        this.setDateTimeToNewModel(clickInfo.event.extendedProps.time)
      }
      this.selectedNode = cloneDeep(clickInfo.event.toPlainObject())
      this.newModel.targets = this.selectedNode.extendedProps.targets
    }
  }

  boardTimezone = ''
  allTheEvents: any[] = []

  getDataFromApi(from: string, to: string): Promise<Array<any>> {
    return new Promise(resolve => {
      Api.getPosts('tg', {
        board: this.board,
        timezone: this.boardTimezone,
        from: from,
        to: to
      }).then(({ data }) => {
        let postsFromApi = cloneDeep(data.posts)
        let recurrEvents: any[] = []
        for (let post of postsFromApi) {
          if (post.run_time.length === 1) {
            post.start = post.run_time[0]
            post.allDay = false
            post.id = post.key
          } else {
            !recurrEvents.length ? recurrEvents = this.setUniqueDates(post) : recurrEvents.push(...this.setUniqueDates(post))
          }
        }
        postsFromApi.push(...recurrEvents)
        let newArr = postsFromApi.filter((p: any) => p.id !== undefined)
        this.allTheEvents = newArr
        this.isLoaded = true
        resolve(newArr)
      })
        .catch((err: any) => {
          errorNotification(err)
          this.$baseTemplate.loader.close()
        })
    })
  }

  uniqueEvents: any[] = []

  setUniqueDates(post: any): any[] {
    const postCopy = post
    let arr: any[] = []
    if (this.calendarApi.view.type !== 'dayGridMonth' && this.isLoaded) {
      let dates: any[] = postCopy.run_time
      return dates.map((d: any) => {
        return {
          ...post,
          id: post.key,
          start: d,
          allDay: false,
        }
      })
    } else {
      const set = new Set(post.run_time.map((e: any) => e.split(' ')[0]))
      set.forEach((t: any) => {
        const idx = postCopy.run_time.findIndex((r: any) => r.split(' ')[0] === t)
        arr.push(post.run_time[idx])
      })
      this.uniqueEvents = arr.map((t: any) => {
        return {
          ...post,
          id: post.key,
          start: t,
          allDay: false,
        }
      })
      return this.uniqueEvents
    }
  }

  isLoaded = false
  isMounted = false

  created() {
    this.currentBoard = this.$store.state.postsPlanner.boards.find((b: Board) => b.board.token_id === Number(this.$route.params['boardId']))
    if (this.currentBoard) {
      this.board = this.currentBoard.board.token_id + '.' + this.currentBoard.board.token_code
      this.boardTimezone = this.currentBoard.timezone

      for (const target of this.currentBoard.targets) {
        this.selectOptionsChats.push({
          'label': target.title,
          'value': target.id,
          'image': {
            'src': target.photo
          },
        })
      }
      this.isMounted = true
    } else {
      errorNotification(this.$t('board_not_found_error'))
      this.$router.replace({ name: "boards" })
    }
  }
}
