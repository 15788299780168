

























































import Api from '@/includes/logic/Api'
import { Board } from '@/views/posts-planner/posts.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class PostInfo extends Mixins<UseFields>(UseFields) {

  @Prop({ type: String }) postKey!: string

  @Prop({ type: Object }) currentBoard!: Board

  @Prop({ type: Function }) boardTargetOptions!: any

  postInfo: Array<{ target: { targetName: string, targetAvatar: string }, error: string | null }> | null = null

  postInfoLoading = false

  mounted(): void {
    this.postInfoLoading = true

    Api.getPostInfo('tg', {
      board: this.currentBoard.board.token_id + '.' + this.currentBoard.board.token_code,
      key: this.postKey
    }).then(({ data }: any) => {
      const response = data.data.result

      this.boardTargetOptions().then((targets: Array<SelectOptionData>) => {
        this.postInfo = response.map((info: any) => {
          const target = targets.find(target => target.value === info.targetId)

          return {
            error: info.error,
            time: moment(info.time).format('YYYY-MM-DD HH:mm:ss'),
            target: {
              targetAvatar: target!.image?.src,
              targetName: target!.label
            }
          }

        })
      })
    }).then(() => {
      this.postInfoLoading = false
    })
  }
}
