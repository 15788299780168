





















































import { PostData } from '@/views/posts-planner/posts.types'

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
const moment = require('moment-timezone');

type GridPost = {
  time:string
  posts?: Array<PostData>
}

@Component
export default class DatesTable extends Vue {

  @Prop() dateEvents !: Array<PostData>

  @Prop({ required: true, default: 60 }) timeTick !: number

  @Prop() selectedDate !: string

  @Prop() timezone !: string

  goToCreatePage(time = moment().add('1', 'h').format('HH:mm')) {
    this.$emit('active', false)
    let queryParams = {
      post_date: this.selectedDate,
      post_time: `${ time }:00`,
    }
    this.$emit('createPost', this.selectedDate, time, queryParams)
  }

  get getDateNow():string {
    return moment().format('HH:mm')
  }

  isPostInTimeValue(timePosts: GridPost): boolean {
    return timePosts.posts !== undefined
  }

  get isDateAfterCurrentDay():boolean {
    return moment(this.selectedDate).isSameOrAfter(moment(), 'd')
  }

  timeGrid(): Array<{time:string}> {
    const from = moment(`${ this.selectedDate } 09:00`)
    const to = moment(`${ this.selectedDate } 19:00`)
    let timeTicks: any[] = []
    while (!from.isSameOrAfter(to)) {
      const time = from.add(this.timeTick, 'minutes').format("HH:mm:ss")
      timeTicks.push({ time: time })
    }
    return timeTicks
  }

  get gridedPosts(): Array<GridPost> {
    let postsToGrid: Array<{time:string}> = this.timeGrid()

    postsToGrid = postsToGrid.map((time: {time:string}) => {
      const foundedPosts: any[] = []
      const postsCopy = [ ...this.dateEvents ]

      postsCopy.forEach((event: PostData) => {
        if (typeof event.run_time !== 'string' && event.run_time!.some((t: string) => t.split(" ")[1] === time.time)) {
          foundedPosts.push(event)
        }
      })

      if (foundedPosts.length) {
        return { 'time': time.time.slice(0, 5), 'posts': [ ...foundedPosts ] }
      } else {
        return { time: time.time.slice(0, 5) }
      }
    })
    return postsToGrid
  }

  isDateAfterMoment(time: string): boolean {
    return moment.tz(`${ this.selectedDate } ${ time }`, this.timezone).isAfter(moment.tz(moment().format(), this.timezone))
  }

  getClass (post:any):string {
    if (post.state === 'Complete') {
      return 'complete'
    } else if (post.run_time.length === 1 && post.state === 'Active') {
      return 'regular'
    } else if (post.run_time.length >= 2 && moment(post.start).isAfter(moment())) {
      return 'repeat'
    } else {
      return 'regular'
    }
  }

}
